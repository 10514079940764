import React from "react"

function Soundcloud({ ...props }) {
  return (
    <iframe
      {...props}
      title="Wildermyth Soundtrack Sample from Soundcloud"
      width="100%"
      height="450"
      src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1609663375&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
    />
  )
}

export default Soundcloud
