import React from "react"
import Layout from "../components/Layout"
import { SectionNoMediaQ } from "../components/sections/Content"
import { Heading } from "../components/parts/General"
import styled from "@emotion/styled"
import gs from "../styles/globals"
import { FormSubscription } from "../components/parts/SubscriptionForm"
import { StaticImage } from "gatsby-plugin-image"
import { DivDefaultWrapper } from "../components/parts/General"
import Soundcloud from "../components/parts/Soundcloud"
import Seo from "../components/Seo"

// #region styling
const Title = styled.h1`
  ${Heading};
  font-size: 3rem;
  margin-bottom: 0;
  color: ${gs.color.purple};
  background: rgb(252, 245, 190);
  background: linear-gradient(
    60deg,
    rgba(252, 245, 190, 1) 40%,
    rgba(235, 171, 27, 1) 90%,
    rgba(138, 96, 37, 1) 100%
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  flex-grow: 1;
`
const Subtitle = styled.h3`
  font-weight: 400;
  flex-grow: 2;
  width: 100%;
`
const SectionSub = styled.section`
  background-color: ${gs.color.shadow};
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  .front-image {
    position: absolute;
    @media all and (min-width: ${gs.size.screen.md}) {
      position: relative;
    }
    &::before {
      pointer-events: none;
      content: "";
      display: block;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(15, 14, 17, 0.7);
      @media all and (min-width: ${gs.size.screen.md}) {
        background: linear-gradient(
          90deg,
          rgba(15, 14, 17, 1) 0%,
          rgba(15, 14, 17, 0) 20%,
          rgba(15, 14, 17, 0) 80%,
          rgba(15, 14, 17, 1) 100%
        );
      }
      z-index: 1;
    }
  }
`
const DivSubscribe = styled(SectionNoMediaQ)`
  z-index: 2;
  box-sizing: border-box;
  margin: 20px 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 920px;
  @media all and (min-width: ${gs.size.screen.md}) {
    position: absolute;
    top: 14px;
    margin-top: 0;
  }
`
const SectionListen = styled(SectionNoMediaQ)`
  border-bottom: 0;
  z-index: 3;
  position: relative;
`
// #endregion

const IndexPage = () => {
  return (
    <Layout>
      <main>
        <SectionSub id="Subscribe">
          <StaticImage
            src="../images/frontPage.jpg"
            alt="A scenic screenshot from Wildermyth of a party of adventurers happily singing around a campfire."
            className="front-image"
            loading="eager"
            layout="fixed"
          />
          <DivSubscribe>
            <Title>Wildermyth OST Coming Soon!</Title>
            <Subtitle>
              Enter your email below if you'd like to stay updated on the
              soundtrack:
            </Subtitle>
            <FormSubscription />
          </DivSubscribe>
        </SectionSub>
        <SectionListen id="Listen" as="section">
          <DivDefaultWrapper>
            <h2>Listen to the demos:</h2>
            <Soundcloud />
          </DivDefaultWrapper>
        </SectionListen>
      </main>
    </Layout>
  )
}

export default IndexPage

export const Head = () => (
  <Seo title="Wildermyth Original Soundtrack Coming Soon! - Home Page" />
)
