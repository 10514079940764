import React, { useState } from "react"
import styled from "@emotion/styled"
import { Input, Label } from "./General"
import Button from "./Button"
import Loading from "./LoadingRing"
import addToMailchimp from "gatsby-plugin-mailchimp"
import { Link } from "gatsby"

// #region styling
const Form = styled.form`
  display: flex;
  margin: 10px 0 0 0;
  width: 100%;
  align-items: center;
  justify-content: center;
`
const DivField = styled.div`
  max-width: 520px;
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  width: 100%;
`
const BtnSubmit = styled(Button)`
  margin-top: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  .loading-icon {
    margin-right: 5px;
  }
`
const H2 = styled.h2`
  margin-bottom: 0;
`
// #endregion

function FormSubscription() {
  const [email, setEmail] = useState("")
  const [result, setResult] = useState("")
  switch (result) {
    default:
      return (
        <Form
          onSubmit={async (e) => {
            setResult("submitting")
            e.preventDefault()
            const r = await addToMailchimp(email)
            setResult(r.result)
          }}
        >
          <DivField>
            <Label htmlFor="email">Email:</Label>
            <Input
              type="text"
              id="email"
              name="email"
              placeholder="example@email.com"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value)
              }}
            />
          </DivField>
          <BtnSubmit type="submit" value="Submit">
            {result === "submitting" ? (
              <>
                <Loading />
                Submitting...{" "}
              </>
            ) : (
              "Submit"
            )}
          </BtnSubmit>
        </Form>
      )
    case "success":
      return <H2>Thanks for subscribing, we'll keep you updated!</H2>
    case "error":
      return (
        <p>
          There was an issue submitting your email. Please refresh the page to
          try again, or <Link to="/contact">contact us</Link> if the issue
          persists
        </p>
      )
  }
}

export { FormSubscription }
